import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      window.location.href = "https://anukin.com";
    }
  },
  {
    path: '/A101/tags/:tag',
    name: 'LaIslaMariscosLapizlazuli',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "laIslaMariscosLapizlazuli" */ '../views/laIslaMariscosLapizlazuli/LaIslaMariscosLapizlazuli.vue'),
  },
  {
    path: '/A105/tags/:tag',
    name: 'SynergyStudio',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "synergyStudio" */ '../views/synergyStudio/SynergyStudio.vue'),
  },
  {
    path: '/:clientId/tags/:tag',
    name: 'GeneralView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "generalView" */ '../views/general/GeneralView.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
  },
  {
    path: '/unregister',
    name: 'Unregister',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "unregister" */ '../views/Unregister.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
