import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import { auth } from "../firebase";
import { getAuth, signOut } from "firebase/auth"
import { db } from "../firebase";
import { doc, getDoc, setDoc, Timestamp} from "firebase/firestore"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userkin: null,
    client: null,
    tag: null,
    newUser: {
      name: '',
      email: '',
      birthday: null,
      
    },
    registerUserFlag: false
  },
  mutations: {
    SET_USERKIN(state, payload){
      state.userkin=payload
    },
    CLEAR_NEWUSER(state, payload){
      state.newUser=payload
    },
    SET_REGISTERUSERFLAG(state, payload){
      state.registerUserFlag=payload
    },
    SET_CLIENT(state, payload){
      state.client=payload
    },
    SET_TAG(state, payload){
      state.tag=payload
    },
  },

  actions: {
    detectuserkin({commit}){
      if(localStorage.getItem('userkin')){
        commit('SET_USERKIN', JSON.parse(localStorage.getItem('userkin')))
      }  
    },

    async logInEmail({commit, state}, email){
      try {
        const emailRef = doc(db, "userskin", email)
        const emailSnap = await (getDoc(emailRef))
        if(emailSnap.data()){
          const user = emailSnap.data()
          user.provider = 'email'
          
          commit('SET_USERKIN', user)
          localStorage.setItem('userkin', JSON.stringify(user))
        }
        else{
          console.log("Userkin no encontrado")
          state.newUser.email = email
          commit('SET_REGISTERUSERFLAG', true)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async registerUser({commit, dispatch}, newUser){
      try {
        //const dateJS = new Date(newUser.birthdate).toUTCString().split('T')[0]
        const dateJS = new Date(newUser.birthday)
        const userTimezoneOffset = dateJS.getTimezoneOffset() * 60000
        const timestamp = new Date(dateJS.getTime() + userTimezoneOffset)
        //console.log(newUser.birthdate)
        //console.log(dateJS)
        //console.log(timestamp)
        await setDoc(doc(db, "userskin", newUser.email), {
          name: newUser.name,
          email: newUser.email,
          birthday: Timestamp.fromDate(timestamp),
          
        })

        //Send Welcome Email
        dispatch('sendWelcomeEmail', newUser)

        //LogIn
        dispatch('logInEmail', newUser.email)
        commit('SET_REGISTERUSERFLAG', false)
        commit('CLEAR_NEWUSER', {})
      } catch (error) {
        console.log(error)
      }
    },

    async sendWelcomeEmail({commit}, user){
      const htmlString = `<div><img src='https://firebasestorage.googleapis.com/v0/b/anukin-ulink-prod-2022.appspot.com/o/images%2Fulink.png?alt=media&token=47381f26-cfdd-4583-8bcf-aba34005725a' style='max-height: 50px;'></img><p>Hola <b>${user.name}</b>,</p><p>¡Bienvenido a la gran comunidad U-link!</p><p>A partir de ahora podrás vivir experiencias interactivas en diferentes establecimientos y obtener grandes recompensas. Recuerda interacturar con todos los puntos U-link que te encuentres en todo el mundo.</p><p>Aquí te compartimos el aviso de privacidad:<br><a href='https://ulink.anukin.com/privacy'>https://ulink.anukin.com/privacy</a></p><p>Si algún dia deseas dejar de formar parte de la gran comunidad U-link, haz click en el siguiente link:<br><a href='https://ulink.anukin.com/unregister'>https://ulink.anukin.com/unregister</a></p><p>Cualquier duda o comentario puedes contactarnos al correo:<br><a href='mailto:info@anukin.com'>info@anukin.com</a><p><p><h4>¡Interectúa, diviértete y gana!</h4><p><p><a href='https://anukin.com'>https://anukin.com</a></p></div>`
      axios.post('https://anukin-notifications.herokuapp.com/email', {
        "to" : user.email,
        "from" : "noreply <notifications@anukin.com>",
        "subject" : "¡Bienvenido a la comunidad U-link!",
        "html" : htmlString
      }) 
    },

    async setClientTag({commit, state}, clientTag){
      try {
        //Client
        const clientRef = doc(db, "clients", clientTag.client)
        const clientSnap = await (getDoc(clientRef))
        //console.log(clientSnap.data())
        commit('SET_CLIENT',clientSnap.data())

        //Tag
        const tagRef = doc(db, `tags/${clientTag.client}/list`, clientTag.tag)
        const tagSnap = await(db, getDoc(tagRef))
        commit('SET_TAG', tagSnap.data())
        if(state.tag){
          localStorage.setItem('urlkin', JSON.stringify(tagSnap.data().url))
        }
        //Si no existe el tag, redireccionar a anukin
        else{
          window.location.replace("https://anukin.com")
        }
        

      } catch (error) {
        console.error(error)
      }
    }
  },

  getters: {
    
  },

  modules: {
  }
})
