import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCkTtDsiy8QdwfuMB7Zvvy9hwz2_u7j-NE",
  authDomain: "anukin-ulink-prod-2022.firebaseapp.com",
  projectId: "anukin-ulink-prod-2022",
  storageBucket: "anukin-ulink-prod-2022.appspot.com",
  messagingSenderId: "1031406451001",
  appId: "1:1031406451001:web:f94339323a1c6d954f50e8",
  measurementId: "G-28DEDDFZZY"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);
const auth = getAuth(app);

export {db, auth}