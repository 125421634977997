<template>
  <div class="home">
    <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <h3 class=" mb-7">Esperando nuevo escaneo...</h3>
    
    <v-btn @click="logOut()" color="primary" elevation="8" rounded block v-if="visible">LogOut</v-btn>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import { mapActions, mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    //HelloWorld
  },

  methods: {
    ...mapActions(['logOut'])
  },

  computed: {
    ...mapState(['userkin']),
    visible(){
      //return localStorage.getItem('userkin') ? true : false
      return Object.keys(localStorage).length > 0 ? true : false
    }

    
  },
}
</script>
