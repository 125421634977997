<template>
  <v-app>
    <v-main class="customBackground">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
.customBackground{
  background: url('./assets/background.jpg');
  background-size: cover
}
</style>
